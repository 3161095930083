<template>
  <div class="Login">
    <el-container>
      <el-header height="45px">
        <i class="el-icon-back back" @click="goback"></i>
        <span>①</span>号教育-英语成绩查询系统
        <div class="usermsg">
          <i class="el-icon-user-solid">
            <span>{{ user.name }}</span>
          </i>
        </div>
      </el-header>
    </el-container>
    <div class="loginbox">
      <span v-if="user.pwd_status == 0" style="color:red; font-size: 12px;"
        >第一次登录需要修改密码</span
      >
      <div class="message">修改密码</div>
      <div id="darkbannerwrap"></div>
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-input
          v-model="form.oldpassword"
          placeholder="请输入旧密码"
          type="password"
        ></el-input>
        <el-input
          v-model="form.password1"
          type="password"
          placeholder="请输入新密码"
        ></el-input>

        <el-input
          v-model="form.password2"
          type="password"
          placeholder="请再次输入新密码"
        ></el-input>

        <el-form-item
          class="yanzheng"
          label="验证码"
          prop="code"
          label-width="60px"
          label-height="40px"
        >
          <el-input v-model="form.code" class="codeinput"></el-input>
          <div class="verify-box" @click="refreshCode">
            <Sidentify :identifyCode="identifyCode"></Sidentify>
          </div>
        </el-form-item>
        <el-button class="logingo" type="primary" @click="updatepwd"
          >登录</el-button
        >
        <div
          style="color: red; text-align: center; font-size: 12px;margin-top: 15px;"
        >
          请牢记密码，忘记密码则无法查询成绩。
        </div>
        <div
          style="color: #666666; text-align: center; font-size: 12px;margin-top: 15px;"
        >
          如有问题请联系19966745796（吴老师）
        </div>
      </el-form>
    </div>
  </div>
</template>
<script>
import Sidentify from "@/components/identify.vue";
export default {
  name: "Login",
  components: { Sidentify },
  props: {
    msg: String,
  },
  data() {
    //字符验证码
    var checkEmailPwd = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入验证码"));
      } else {
        this.$http
          .post(this.$store.state.baseUrl + "/verifycode", { code: value })
          .then((res) => {
            //   console.log(res)
            if (res.data.code == 200) {
              this.login_tatus = true;
              callback();
            } else {
              callback(new Error("验证码错误"));
            }
          });
      }
    };

    return {
      form: {
        oldpassword: "",
        password1: "",
        password2: "",
      },
      rules: {
        //邮箱验证码
        code: [{ validator: checkEmailPwd, trigger: "blur" }],
      },
      login_tatus: false,
      identifyCode: "",
      flage: false,
      btnText: "发送验证码",
      user: {},
    };
  },
  methods: {
    refreshCode() {
      this.identifyCode = "";
      this.$http.get(this.$store.state.baseUrl + "/getcode", {}).then((res) => {
        // console.log(res)
        if (res.data.code == 200) {
          this.identifyCode = res.data.msg;
        } else {
          this.$message.warning({
            message: "操作太频繁",
            type: "warning",
          });
        }
      });
    },
    yanzhengma() {
      let data = {
        email: this.ruleForm.email,
      };
      if (!data.email) {
        this.open("请输入邮箱", "error");
        return;
      }
      var count = 60;
      this.flage = true;
      this.btnText = count + "s";
      var btnTimer = setInterval(() => {
        count--;
        this.btnText = count + "s";
        if (count == 0) {
          this.flage = false;
          this.btnText = "发送验证码";
          clearInterval(btnTimer);
        }
      }, 1000);
      this.$http
        .get(this.$store.state.baseUrl + "getcode", data)
        .then((res) => {
          if (res.data.code == 200) {
            this.open("发送成功", "success");
          } else {
            this.open("发送失败", "error");
          }
        });
    },
    updatepwd() {
      console.log(this.form);
      var that = this;
      if (!this.form.oldpassword) {
        this.$message({
          message: "请先输入账号密码！",
          type: "warning",
        });
        return;
      }
      if (this.form.password1 != this.form.password2) {
        this.$message({
          message: "两次密码不一致",
          type: "warning",
        });
        return;
      }

      var url = this.$store.state.baseUrl + "/updatepwd";
      this.$http
        .post(url, {
          id: that.user.id,
          oldpassword: that.form.oldpassword,
          newpassword: that.form.password2,
        })
        .then(function(response) {
          if (response.data.code == 404) {
            that.$message({
              message: "旧密码错误！",
              type: "warning",
            });
            return;
          }
          if (response.data.code == 200) {
            that.$alert("修改密码成功！返回登录页", "提示", {
              confirmButtonText: "确定",
              callback: () => {
                that.$router.push("/");
              },
            });
          }
        })
        .catch(function(error) {
          console.log(error);
          that.$message({
            message: "服务器异常！" + error,
            type: "warning",
          });
        });
    },
    goback() {
      this.$router.go(-1);
    },
  },
  created() {
    let cookie = this.$Cookie.get("cookieUser");
    console.log(cookie);
    if (cookie) {
      this.user = JSON.parse(cookie);
    }
    this.refreshCode();
  },
};
</script>
<style lang="scss">
.Login {
  width: 100%;
  height: 100%;
  background: url(../assets/images/bg.png) no-repeat center;
  background-size: cover;
  overflow: hidden;
  .el-header {
    background-color: #189f92;
    color: #fff;
    text-align: left;
    line-height: 45px;
    font-size: 15px;
    .el-select,
    .el-select .el-input.el-input__inner {
      height: 28px;
      width: 40px;

      line-height: 28px;
    }
    .el-input--mini .el-input__inner {
      background: none;
      border: none;
    }
    .back {
      margin-right: 15px;
    }
    .usermsg {
      font-size: 15px;
      position: absolute;
      height: 45px;
      width: 130px;
      line-height: 45px;
      right: 0;
      top: 0;
      text-align: center;
      span {
        margin: 0 10px;
      }
    }
  }
  .slidebox {
    position: absolute;
    z-index: 99;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .el-button {
    margin-top: 15px;
    width: 100%;
    background-color: #189f92;
  }
  .renji {
    background-color: #0cd838;
    color: #fff;
  }
  .loginbox {
    margin: 120px auto 0 auto;
    min-height: 420px;
    max-width: 420px;
    padding: 40px;
    background-color: #eeeeee;
    margin-left: auto;
    margin-right: auto;
    border-radius: 4px;
    /* overflow-x: hidden; */
    box-sizing: border-box;
    .message {
      font-size: 14px;
      margin: 10px 0 0 -58px;
      padding: 18px 10px 18px 60px;
      background: #189f92;
      position: relative;
      color: #fff;
      font-size: 16px;
    }
    .yanzheng {
      .el-form-item__content {
        display: flex;
        height: 40px;
      }
      .el-input {
        width: calc(100% - 193px);
        margin-right: 20px;
      }
      .verify-box {
        width: 121px;
        height: 40px;
        .s-canvas {
          height: 100%;
        }
      }
    }

    .change-pwd {
      text-align: right;
      color: #8b9db6;
      font-size: 12px;
    }

    #darkbannerwrap {
      background: url(../assets/images/aiwrap.png);
      width: 18px;
      height: 10px;
      margin: 0 0 20px -58px;
      position: relative;
    }
    .el-form {
      width: 100%;
      // background-color: red;
      .el-input {
        // border: 1px solid #dcdee0;
        vertical-align: middle;
        border-radius: 3px;
        height: 50px;
        // padding: 0px 16px;
        font-size: 12px;
        color: #555555;
        outline: none;
        width: 100%;
        box-sizing: border-box;
      }
      .el-select {
        width: 100%;
      }
      .logingo {
        color: #fff;
        padding: 0;
        background-color: #189f92;
        height: 40px;
        margin: 0;
        margin-top: 15px;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .Login {
    .loginbox {
      .message {
        font-size: 14px;
        margin: 0;
        margin-bottom: 20px;
        padding: 18px 72px 18px 60px;
        background: #189f92;
        position: relative;
        color: #fff;
        font-size: 16px;
      }
      #darkbannerwrap {
        display: none;
      }
    }
  }
}
</style>
