<template>
  <div class="StuPage">
    <el-input class="like" v-model="input" placeholder="关键词查询"></el-input>
    <el-button type="primary" icon="el-icon-search" class="likebtn"
      >搜索</el-button
    >
    <div style="margin-bottom: 10px; text-align: left">
      <el-button type="success" @click="dialogFormVisible = true"
        >添加学生</el-button
      >
      <el-button type="danger" v-if="deletebox" @click="handleDelete"
        >删除选中</el-button
      >
    </div>
    <el-table
      :data="tableData"
      border
      style="width: 100%"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column type="index" label="序号" width="50"> </el-table-column>
      <el-table-column
        prop="grade"
        label="年级"
        width="120"
        sortable
        column-key="grade"
        :filters="[
          { text: '三年级', value: '三年级' },
          { text: '四年级', value: '四年级' },
          { text: '五年级', value: '五年级' },
          { text: '六年级', value: '六年级' },
          { text: '小升初', value: '小升初' },
          { text: '初二', value: '初二' },
        ]"
        :filter-method="filterHandler"
      ></el-table-column>
      <el-table-column
        prop="classes"
        label="班级"
        width="120"
        sortable
        column-key="classes"
        :filters="[
          { text: '1班', value: '1班' },
          { text: '2班', value: '2班' },
        ]"
        :filter-method="filterHandler"
      ></el-table-column>
      <el-table-column prop="name" label="姓名" width=""></el-table-column>
      <el-table-column prop="password" label="密码" width=""></el-table-column>
      <el-table-column prop="sNum" label="学号" width="150"></el-table-column>
      <el-table-column prop="sex" label="性别" width=""></el-table-column>
      <el-table-column prop="parent1" label="家长" width=""></el-table-column>
      <el-table-column prop="phone1" label="电话" width=""></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="danger"
            @click="handleDelete(scope.$index, scope.row.id)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      layout="prev, pager, next"
      :total="100"
      @current-change="current_change"
    >
    </el-pagination>
    <div class="addbox">
      <el-dialog title="添加学生" :visible.sync="dialogFormVisible">
        <el-form :model="form">
          <el-form-item label="请输入学生姓名" :label-width="formLabelWidth">
            <el-input v-model="form.name" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="年级" :label-width="formLabelWidth">
            <el-select v-model="form.grade" placeholder="请选择活动区域">
              <el-option label="三年级" value="三年级"></el-option>
              <el-option label="四年级" value="四年级"></el-option>
              <el-option label="五年级" value="五年级"></el-option>
              <el-option label="六年级" value="六年级"></el-option>
              <el-option label="基础班" value="基础班"></el-option>
              <el-option label="小升初" value="小升初"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="班级" :label-width="formLabelWidth">
            <el-select v-model="form.classes" placeholder="请选择活动区域">
              <el-option label="1班" value="1班"></el-option>
              <el-option label="2班" value="2班"></el-option>
            </el-select>
          </el-form-item>

          <!-- <el-form-item label="性别" :label-width="formLabelWidth">
            <el-select v-model="form.sex" placeholder="请选择活动区域">
              <el-option label="男" value="男"></el-option>
              <el-option label="女" value="女"></el-option>
            </el-select>
          </el-form-item> -->
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="addstu">确 定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
export default {
  name: "StuPage",
  components: {},
  data() {
    return {
      //菜单配置
      input: "",
      tableData: [],
      multipleSelection: [],
      dialogFormVisible: false,
      deletebox: false,
      formLabelWidth: "200",
      form: {
        name: "",
        grade: "三年级",
        classes: "1班",
        sex: "女",
      },
    };
  },
  methods: {
    current_change(val) {
      this.findUser(val);
    },
    filterHandler(value, row, column) {
      const property = column["property"];
      return row[property] === value;
    },
    findUser(page) {
      var that = this;
      var url = this.$store.state.baseUrl + "/findUser";
      var parm = { pageSize: 10, currentPage: page };
      this.$http
        .post(url, parm)
        .then(function(response) {
          if (response.data.code == 200) {
            that.tableData = response.data.result.data;
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },

    handleSelectionChange(val) {
      this.multipleSelection = val;
      if (this.multipleSelection.length > 0) {
        this.deletebox = true;
      } else {
        this.deletebox = false;
      }
    },
    handleDelete(index, id) {
      console.log(index);
      var that = this;
      this.$confirm("确定删除吗？")
        .then((res) => {
          console.log(res);
          var idarr = [];
          if (that.multipleSelection.length == 0) {
            idarr.push(id);
          } else {
            that.multipleSelection.forEach((item) => {
              idarr.push(item.id);
            });
          }
          console.log();

          var url = this.$store.state.baseUrl + "/delestu";
          var parm = { idarr };
          this.$http
            .post(url, parm)
            .then(function(response) {
              if (response.data.code == 200) {
                console.log(id);
                const newArr = that.tableData.filter((item) => {
                  if (id) {
                    return item.id != id;
                  } else {
                    console.log(idarr.indexOf(item.id));
                    return idarr.indexOf(item.id) == -1;
                  }
                });
                that.tableData = [];
                that.tableData = newArr;
              }
            })
            .catch(function(error) {
              console.log(error);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    addstu() {
      var that = this;

      var url = that.$store.state.baseUrl + "/addstu";
      var parm = { user: that.form };
      this.$http
        .post(url, parm)
        .then(function(response) {
          if (response.data.code == 200) {
            console.log(response.data);
            that.dialogFormVisible = false;
            that.form.name = "";
            that.$message({
              message: "成功！",
              type: "success",
            });
            that.findUser();
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
  },

  created() {
    this.findUser(1);
  },
};
</script>
<style lang="scss">
.StuPage {
  width: 100%;
  height: 100%;
  display: block;
  .like {
    width: 500px;
    margin: 50px auto;
    border-radius: 5px 0 0 5px;
    .el-input__inne {
      border-radius: 5px 0 0 5px;
    }
  }

  .likebtn {
    border-radius: 0 5px 5px 0;
  }
}
</style>
