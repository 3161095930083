<template>
  <div class="Scoreslist">
    <h3>{{ grade }}{{ classes }}英语成绩</h3>
    <el-table :data="tableData" border style="width: 100%">
      <el-table-column type="index" label="序号" width="50"> </el-table-column>

      <el-table-column prop="term" label="学期" width=""> </el-table-column>
      <el-table-column prop="grade" label="年级" width=""> </el-table-column>
      <el-table-column prop="classes" label="班级" width=""> </el-table-column>
      <el-table-column prop="exam" label="考试项目" width=""> </el-table-column>
      <el-table-column prop="createdAt" label="时间" width="200">
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button size="mini" @click="handleEdit(scope.$index, scope.row)"
            >编辑</el-button
          >
          <el-button
            size="mini"
            type="danger"
            @click="handleDelete(scope.$index, scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
export default {
  name: "Scoreslist",
  components: {},
  data() {
    return {
      grade: "",
      classes: "",
      tableData: [],
    };
  },
  methods: {
    getlist() {
      this.tableData = [];
      this.grade = this.$route.query.grade;
      this.classes = this.$route.query.classes;
      var that = this;
      var url = this.$store.state.baseUrl + "/findexamgroup";
      var parm = {
        grade: this.$route.query.grade,
        classes: this.$route.query.classes,
      };
      this.$http
        .post(url, parm)
        .then(function(response) {
          if (response.data.code == 200) {
            console.log(response.data.result.data);
            that.tableData = [];
            that.tableData = response.data.result.data;
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
  },
  created() {
    this.getlist();
  },

  watch: {
    $route: "getlist",
  },
};
</script>
<style lang="scss"></style>
