<template>
  <div class="StuHome">
    <el-container>
      <el-header height="45px"
        >①号教育-英语成绩查询系统
        <div class="usermsg">
          <i class="el-icon-user-solid">
            <span>{{ user.name }}</span>
          </i>
          <el-select size="mini" width="30px" v-model="meui" placeholder="菜单">
            <el-option :value="option.value1">>退出</el-option>
            <el-option :value="option.value2">>修改密码</el-option>
          </el-select>
        </div>
      </el-header>
    </el-container>
    <el-container class="mincontainer">
      <el-container>
        <Msg v-if="isupdatepwd" :lists="list" class="scrollContainer" />
        <el-main>
          <div
            style="color: #606266;
             margin-bottom: 10px;"
          >
            请选择需要查询的成绩信息>>>
          </div>
          <div
            class="fg"
            style=" border: 0.5px solid #e6e6e6;margin-bottom: 5px;"
          ></div>
          <div class="selectbox">
            <el-select v-model="term" placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <el-select class="you" v-model="grade" placeholder="请选择">
              <el-option
                v-for="item in options3"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <el-select v-model="exam" placeholder="请选择">
              <el-option
                v-for="item in options2"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <el-input
              class="you"
              v-model="inputlikes"
              placeholder="关键词查询"
              suffix-icon="el-icon-search"
            ></el-input>
            <!-- <el-button type="primary">查询</el-button> -->
          </div>
          <div
            class="fg"
            style=" border: 0.5px solid #e6e6e6;margin-bottom: 5px;"
          ></div>
          <div class="showbox">
            <el-table
              :data="tableData"
              style="width: 100%"
              :default-sort="{ prop: 'num', order: '' }"
            >
              <el-table-column type="index" width="50" label="序号">
              </el-table-column>
              <el-table-column prop="date" label="考试时间" width="">
                <template slot-scope="scope">
                  <div>{{ scope.row.grade }}{{ scope.row.term }}</div>
                  <!-- <div>{{ scope.row.term }}</div> -->
                </template>
              </el-table-column>
              <el-table-column prop="exam" label="项目" width="80">
                <template slot-scope="scope">
                  {{ scope.row.exam }}
                </template>
              </el-table-column>
              <el-table-column prop="" label="成绩" sortable width="">
                <template slot-scope="scope">
                  <el-button
                    @click="handleClick(tableData, scope.$index)"
                    type="text"
                    size="small"
                  >
                    <span
                      style="color: red;margin-right: 10px; font-weight: 800;"
                      >{{ scope.row.scores }}</span
                    >分析</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
            <!-- <div
              style="
              font-size: 8px;
              color: #e48383;
              margin: 5px;
              text-align: center;"
            >
              点击成绩查看排名
            </div> -->
          </div>
          <div
            style="color: #666666; text-align: center; font-size: 12px;margin-top: 15px;"
          >
            如有问题请联系编程老师19966745796（吴老师）
          </div>
        </el-main>
      </el-container>
    </el-container>
    <div
      style="color: #666666; text-align: center; font-size: 12px;margin-top: 15px ;"
    >
      网站所有权(吴*文)©桂ICP备2020007545号
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Msg from "@/components/Msg";
export default {
  name: "StuHome",
  components: { Msg },
  data() {
    return {
      isupdatepwd: false,
      list: ["点击右上角[V]，修改默认密码"],
      //菜单配置
      option: {
        value1: 1,
        value2: 2,
      },
      meui: 0,
      slidebox_status: false,
      //菜单配置
      term: "",
      options: [
        {
          value: "第一学期",
          label: "第一学期",
        },
        {
          value: "第二学期",
          label: "第二学期",
        },
      ],
      exam: "全部考试",
      options2: [],
      grade: "",
      options3: [],
      inputlikes: "",

      tableData: [],
      user: {},
    };
  },
  watch: {
    meui(val) {
      console.log(val);
      if (val == 1) {
        this.$Cookie.remove("cookieUser");
        this.$router.push("/");
      }
      if (val == 2) {
        this.$router.push("/updatepwd");
      }
    },
    term() {
      this.findall();
    },
    exam() {
      this.findall();
    },
    grade() {
      this.findall();
    },
    inputlikes(val) {
      var url = this.$store.state.baseUrl + "/getlikescores";
      var that = this;
      var parm = {
        value: val,
        sName: that.user.name,
      };
      this.$http
        .post(url, parm)
        .then(function(response) {
          // console.log(response.data.result);
          if (response.data.code == 404) {
            return;
          }
          let data = response.data.result;
          that.tableData = [];
          data.forEach((item) => {
            that.tableData.push(item);
          });
        })
        .catch(function(error) {
          console.log(error);
        });
    },
  },
  created() {
    let cookie = this.$Cookie.get("cookieUser");
    if (!cookie) {
      this.$router.push("/");
      return;
    }
    this.user = JSON.parse(cookie);
    this.inittitle();
    if (this.user.pwd_status == 1) {
      this.isupdatepwd = true;
      // this.$router.push("/updatepwd");
      // return;
    }
  },
  methods: {
    outlogin() {
      console.log("推出");
      this.$router.push("/ee");
    },
    formatter(row) {
      return row.address;
    },
    findall() {
      var url = this.$store.state.baseUrl + "/getonescores";
      var that = this;
      var parm = {
        term: that.term,
        grade: that.grade,
        sName: that.user.name,
        exam: that.exam,
      };
      this.$http
        .post(url, parm)
        .then(function(response) {
          // console.log(response.data.result);
          if (response.data.code == 404) {
            return;
          }
          let data = response.data.result;
          // console.log(data);
          that.tableData = [];
          data.forEach((item) => {
            if (item.scores) {
              that.tableData.push(item);
            }
          });
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    isOneTerm(month) {
      let isterm = [9, 10, 11, 12, 1, 2];

      if (isterm.indexOf(month) > -1) {
        return true;
      } else {
        return false;
      }
    },

    inittitle() {
      var url = this.$store.state.baseUrl + "/findexam";
      var that = this;
      var parm = {
        name: that.user.name,
      };
      this.$http
        .post(url, parm)
        .then(function(response) {
          // console.log(response.data.result);
          if (response.data.code == 404) {
            return;
          }
          let data = response.data.result.data;

          that.term = data[0].term;
          that.grade = data[0].grade;
          data.forEach((item) => {
            that.options2.push({
              value: item.exam,
              label: item.exam,
            });
            that.options3.push({
              value: item.grade,
              label: item.grade,
            });
          });
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    handleClick(data, index) {
      let params = {};
      params.index = index;
      params.data = data;
      this.$store.state.stushowdata = params;
      sessionStorage.setItem("showsocres", JSON.stringify(params));
      this.$router.push({ name: "StuShow" });
    },
  },
};
</script>
<style lang="scss">
.StuHome {
  width: 100%;
  height: 100%;
  .mincontainer {
    height: 90%;
    background-color: #ffffff;
  }
  .el-header {
    background-color: #189f92;
    color: #fff;
    text-align: left;
    line-height: 45px;
    font-size: 15px;
    .el-select,
    .el-select .el-input.el-input__inner {
      height: 28px;
      width: 40px;

      line-height: 28px;
    }
    .el-input--mini .el-input__inner {
      background: none;
      border: none;
    }
    .usermsg {
      font-size: 15px;
      position: absolute;
      height: 45px;
      width: 130px;
      line-height: 45px;
      right: 0;
      top: 0;
      text-align: center;
      span {
        margin: 0 10px;
      }
    }
  }
  .el-table .cell {
    font-size: 12px;
    text-align: center;
  }

  .scrollContainer {
    color: red;
    position: absolute;
    left: 10px;
    font-size: 10px;

    margin-left: 10px;
    width: 100%;
    height: 30px;
    line-height: 30px;
    overflow: hidden;
  }

  .el-main {
    background-color: #ffffff;
    color: #333;
    text-align: left;
    // height: 100%;
    .selectbox {
      .el-select,
      .el-input {
        width: 150px;
        margin-bottom: 5px;
        margin-right: 15px;
      }

      .el-button {
        width: 90px;
        height: 38px;
        background-color: #189f92;
        border-color: #189f92;
      }
    }
  }

  .el-footer {
    background-color: #8d939e;
    color: #fff;
    text-align: center;
    // line-height: 60px;
    // height: 200px;
  }

  body > .el-container {
    margin-bottom: 40px;
  }

  .el-container:nth-child(5) .el-aside,
  .el-container:nth-child(6) .el-aside {
    line-height: 260px;
  }

  .el-container:nth-child(7) .el-aside {
    line-height: 320px;
  }
}
@media screen and (max-width: 750px) {
  .StuHome {
    .el-main {
      .selectbox {
        .el-select,
        .el-input {
          width: 150px;
          margin-bottom: 5px;
          margin-right: 0px;
        }
        .you {
          margin-left: 10px;
        }
      }
    }
  }
}
</style>
