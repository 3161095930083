<template>
  <div class="Myvue">
    <div class="box" @click="gotolist('六年级', '1班')">
      <div class="c6"></div>
      <div class="title">六年级（1）班</div>
    </div>
    <div class="box" @click="gotolist('六年级', '2班')">
      <div class="c6"></div>
      <div class="title">六年级（2）班</div>
    </div>
    <div class="box" @click="gotolist('五年级', '1班')">
      <div class="c5"></div>
      <div class="title">五年级（1）班</div>
    </div>
    <div class="box" @click="gotolist('四年级', '1班')">
      <div class="c4"></div>
      <div class="title">四年级（1）</div>
    </div>
    <div class="box" @click="gotolist('三年级', '1班')">
      <div class="c3"></div>
      <div class="title">三年级（1）班</div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Myvue",
  components: {},
  data() {
    return {};
  },
  methods: {
    gotolist(grade, classes) {
      this.$router.push(
        "/thome/Scoreslist?grade=" + grade + "&classes=" + classes
      );
    },
  },
  created() {},
};
</script>
<style lang="scss">
.Myvue {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  .box {
    margin-right: 10px;
    margin-bottom: 10px;
    width: 32%;
    height: 250px;
    box-shadow: 10px 10px 5px #888888;
    text-align: center;
    cursor: pointer;
  }
  .title {
    height: 50px;
    color: #167fe8;
    line-height: 50px;
  }
  .c6 {
    background: url(../assets/images/6.png) repeat;
    background-size: 100% 100%;
    height: 200px;
  }
  .c5 {
    height: 200px;
    background: url(../assets/images/5.png) repeat;
    background-size: 100% 100%;
  }
  .c4 {
    height: 200px;
    background: url(../assets/images/4.png) repeat;
    background-size: 100% 100%;
  }
  .c3 {
    height: 200px;
    background: url(../assets/images/3.png) repeat;
    background-size: 100% 100%;
  }
}
</style>
