<template>
  <div class="Upload">
    <div class="box">
      <h5>录入成绩</h5>
      <el-upload
        class="upload-demo"
        drag
        :action="scoresurl"
        :on-success="scoresuccess"
        multiple
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
      </el-upload>
      <div class="btn">
        <el-button type="primary">下载模板</el-button>
        <el-button type="success" @click="uploadscore">上传</el-button>
      </div>
    </div>
    <div class="box">
      <h3>录入学生</h3>
      <el-upload
        class="upload-demo"
        drag
        :action="stuurl"
        :on-success="stusuccess"
        multiple
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
      </el-upload>
      <div class="btn">
        <el-button type="primary">下载模板</el-button>
        <el-button type="success" @click="uploadstu">上传</el-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Upload",
  components: {},
  data() {
    return {
      scoresurl: this.$store.state.baseUrl + "/scoresExecl",
      updteScoressql: this.$store.state.baseUrl + "/scoressql",
      stuurl: this.$store.state.baseUrl + "/stuExecl",
      updteStudentsql: this.$store.state.baseUrl + "/stutsql",
      scoresfilePath: "",
      stufilePath: "",
    };
  },
  methods: {
    uploadscore() {
      var that = this;
      var url = that.updteScoressql;
      var parm = { filePath: that.scoresfilePath };
      this.$http
        .post(url, parm)
        .then(function(response) {
          if (response.data.code == 200) {
            console.log(response.data);
            that.$message({
              message: "上传成功！",
              type: "success",
            });
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    scoresuccess(data) {
      this.scoresfilePath = data.filePath;
    },
    uploadstu() {
      var that = this;
      var url = that.updteStudentsql;
      var parm = { filePath: that.stufilePath };
      this.$http
        .post(url, parm)
        .then(function(response) {
          if (response.data.code == 200) {
            console.log(response.data);
            that.$message({
              message: "成功！",
              type: "success",
            });
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    stusuccess(data) {
      this.stufilePath = data.filePath;
    },
  },
  created() {},
};
</script>
<style lang="scss">
.Upload {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  .box {
    width: 30%;

    border: 1px solid #ccc;
    border-radius: 10px;
    margin: 10px;
    box-shadow: 10px 10px 5px #888888;
    height: 350px;
    position: relative;
    min-width: 400px;
    min-height: 350px;
    .btn {
      display: block;
      width: 100%;
      position: absolute;
      bottom: 0px;
      display: flex;
      justify-content: space-between;
      .el-button {
        margin: 20px;
      }
    }
  }
}
</style>
