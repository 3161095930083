<template>
  <div class="StuShow">
    <el-container>
      <el-header height="45px">
        <i class="el-icon-back back" @click="goback"></i>
        ①号教育-英语成绩查询系统
        <div class="usermsg">
          <i class="el-icon-user-solid">
            <span>{{ user.name }}</span>
          </i>
        </div>
      </el-header>
    </el-container>
    <div class="mybox" style="padding:0 20px">
      <h4 style="margin:10px 0">考试成绩</h4>
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column prop="date" label="时间" width="">
          <template slot-scope="scope">
            <div>{{ scope.row.grade }}</div>
            <div>{{ scope.row.term }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="exam" label="考试项目" width="">
        </el-table-column>
        <el-table-column prop="scores" label="成绩" width="50">
        </el-table-column>
        <!-- <el-table-column
        prop="pjf"
        label="平均分"
        style="font-size: 10px;"
        width="60"
      >
      </el-table-column> -->
        <!-- <el-table-column prop="banjipm" label="班级排名" width="70">
      </el-table-column>
      <el-table-column prop="nianjipm" label="年级排名" width="70"> -->
        <!-- </el-table-column> -->
      </el-table>
      <div
        class="fg"
        style=" border: 0.5px solid #e6e6e6;margin-bottom: 5px;"
      ></div>
      <h4>近期考试统计</h4>
      <div id="tu3" :style="{ width: '100%', height: '300px' }"></div>
      <div
        class="fg"
        style=" border: 0.5px solid #e6e6e6;margin-bottom: 5px;"
      ></div>
      <h4>AI分析</h4>
      <div>
        本次考试超过约<span style="color:red">{{ aimsg }} </span> %的同学
      </div>

      <div v-if="aips > 0">
        与上次考试相比提高了 <span style="color:red">{{ aips }} %</span>
      </div>
      <div>
        <span
          style="color: #b9a545;
    font-size: 12px;

    line-height: 55px;"
          >{{ gls }}
        </span>
      </div>
      <div
        style="color: #666666; text-align: center; font-size: 12px;margin-top: 15px;"
      >
        如有问题请联系编程老师19966745796（吴老师）
      </div>
    </div>
    <div
      style="color: #666666; text-align: center; font-size: 12px;margin-top: 15px ;"
    >
      网站所有权(吴*文)©桂ICP备2020007545号
    </div>
  </div>
</template>
<script>
export default {
  name: "StuShow",
  components: {},
  data() {
    return {
      scoreslist: [],
      tableData: [],
      nowscores: {},
      user: {},
      term: "",
      grade: "",
      banjicount: 0,
      banjipm: 0,
      aimsg: "",
      aips: 0,
      nowscoresindex: 0,
      gls: "",
      guli: [
        " 得道多助，失道寡助。",
        "宜将剩勇追穷寇，不可沽名学霸王。",
        "但愿人长久，千里共婵娟。",
        "他山之石，可以攻玉",
        "读书切戒在慌忙，涵泳工夫兴味长。",
        " 穷且益坚，不坠青云之志。",
        "日日行，不怕千万里；常常做，不怕千万事。",
        "投我以桃，报之以李。",
        "宝剑锋从磨砺出，梅花香自苦寒来。",
        "一寸光阴一寸金，寸金难买寸光阴。",
        "大直若屈，大巧若拙，大辩若讷。",
        "风流不在谈锋胜，袖手无言味最长。",
        "忧劳可以兴国，逸豫可以亡身。",
        "天变不足畏，祖宗不足法，人言不足恤。",
        "欲穷千里目，更上一层楼。",
        " 若要功夫深，铁杵磨成针。",
        "不塞不流，不止不行。",
        "人生自古谁无死？留取丹心照汗青。",
        "精诚所加，金石为开。",
        "不入虎穴，焉得虎子？",
      ],
    };
  },
  mounted() {
    this.drawTu();
    this.paiming();
    this.gls = this.guli[Math.ceil(Math.random() * 20)];
  },
  methods: {
    isOneTerm(month) {
      let isterm = [9, 10, 11, 12, 1, 2];

      if (isterm.indexOf(month) > -1) {
        return true;
      } else {
        return false;
      }
    },

    drawTu() {
      var exams = [];
      var scores = [];
      this.scoreslist.forEach((item) => {
        exams.push(item.exam);
        scores.push(item.scores);
      });

      exams.reverse();
      scores.reverse();
      let myChart = this.$echarts.init(document.getElementById("tu3"));
      myChart.resize();
      // 绘制图表
      var option;
      option = {
        title: {
          text: "",
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: ["成绩"],
        },

        toolbox: {
          feature: {
            mark: { show: true }, // 辅助线标志
            dataView: { show: true, readOnly: false }, // 数据视图
            magicType: { show: true, type: ["line"] }, // 动态类型转换（折线柱形互转）
            restore: { show: true }, // 还原
            saveAsImage: { show: true },
          },
        },
        calculable: true, // 启用拖拽，重新计算
        xAxis: {
          type: "category",
          data: exams,
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            itemStyle: {
              normal: {
                lineStyle: {
                  color: "#189F92",
                },
              },
            },
            label: { show: false }, //顶部显示数值
            name: "成绩",
            type: "bar",
            data: scores,
          },
          {
            itemStyle: {
              normal: {
                lineStyle: {
                  color: "#189F92",
                },
              },
            },
            label: { show: true }, //顶部显示数值
            name: "成绩",
            type: "line",
            data: scores,
          },
        ],
      };
      option && myChart.setOption(option);
    },
    goback() {
      this.$router.go(-1);
    },
    paiming() {
      var url = this.$store.state.baseUrl + "/paiming";
      var that = this;
      var parm = {
        sName: that.nowscores.sName,
        grade: that.nowscores.grade,
        term: that.nowscores.term,
        classes: that.user.classes,
        exam: that.nowscores.exam,
      };
      this.$http
        .post(url, parm)
        .then(function(response) {
          if (response.data.code == 200) {
            console.log(response.data);
            that.nowscores.pjf = response.data.data.pjf;
            that.nowscores.banjipm = response.data.data.banjipm;
            that.nowscores.nianjipm = response.data.data.nianjipm;
            that.banjicount = response.data.data.banjicount;
            that.banjipm = response.data.data.banjipm;
            that.tableData.push(that.nowscores);

            that.aifenxi();
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    aifenxi() {
      let cg = (this.banjicount - this.banjipm) / this.banjicount;
      cg = cg.toFixed(2) * 100;
      this.aimsg = cg;
      let val1 = this.scoreslist[this.nowscoresindex + 1].scores;
      let val2 = this.scoreslist[this.nowscoresindex].scores;
      let ps = (val2 - val1) / val1;
      this.aips = ps.toFixed(2) * 100;
    },
  },

  created() {
    let cookie = this.$Cookie.get("cookieUser");

    if (!cookie) {
      this.$router.push("/");
      return;
    }
    this.user = JSON.parse(cookie);
    var session = sessionStorage.getItem("showsocres");
    var data = JSON.parse(session);
    this.scoreslist = data.data;
    this.nowscores = data.data[data.index];
    this.nowscoresindex = data.index;
    this.grade = this.nowscores.grade;
    this.term = this.nowscores.term;
    console.log(this.nowscoresindex);
  },
};
</script>
<style lang="scss">
.StuShow {
  .el-header {
    background-color: #189f92;
    color: #fff;
    text-align: center;
    line-height: 45px;
    font-size: 15px;
    .back {
      position: absolute;
      line-height: 45px;
      left: 10px;
      font-size: 15px;
    }
    .el-select,
    .el-select .el-input.el-input__inner {
      height: 28px;
      width: 40px;

      line-height: 28px;
    }
    .el-input--mini .el-input__inner {
      background: none;
      border: none;
    }
    .usermsg {
      font-size: 15px;
      position: absolute;
      height: 45px;
      width: 130px;
      line-height: 45px;
      right: 0;
      top: 0;
      text-align: center;
      span {
        margin: 0 10px;
      }
    }
  }
  background-color: #fff;
  padding: 0;
  text-align: center;
  .el-table {
    background-color: #fff;
    padding: 0;
  }
  .el-table th > .cell {
    font-size: 12px;
  }

  .el-table .cell {
    font-size: 12px;
    text-align: center;
  }

  .tubox {
    #tu1 {
      position: relative;
      left: 50%;
      transform: translate(-50%, 0);
    }
    #tu2 {
      position: relative;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }
}
</style>
