<template>
  <div class="AdminLogin">
    <div class="loginbox">
      <div class="message">管理员入口</div>
      <div id="darkbannerwrap"></div>
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-input
          v-model="form.userId"
          placeholder="账号：(管理员指定账号)"
          type="name"
        ></el-input>
        <el-input
          v-model="form.password"
          type="password"
          placeholder="密码"
        ></el-input>
        <el-form-item
          class="yanzheng"
          label="验证码"
          prop="code"
          label-width="60px"
          label-height="40px"
        >
          <el-input v-model="form.code" class="codeinput"></el-input>
          <div class="verify-box" @click="refreshCode">
            <Sidentify :identifyCode="identifyCode"></Sidentify>
          </div>
        </el-form-item>
        <el-button type="primary" @click="login">登录</el-button>
        <div
          style="color: #666666; text-align: center; font-size: 12px;margin-top: 15px;"
        >
          推荐使用Microsoft Edge、Chrome浏览器
        </div>
      </el-form>
    </div>
  </div>
</template>
<script>
import Sidentify from "@/components/identify.vue";
export default {
  name: "AdminLogin",
  components: { Sidentify },
  props: {
    msg: String,
  },
  data() {
    //字符验证码
    var checkEmailPwd = (rule, value, callback) => {
      console.log(this.form);
      if (!value) {
        callback(new Error("请输入验证码"));
      } else {
        this.$http
          .post(this.$store.state.baseUrl + "/verifycode", { code: value })
          .then((res) => {
            //   console.log(res)
            if (res.data.code == 200) {
              this.login_tatus = true;
              callback();
            } else {
              callback(new Error("验证码错误"));
            }
          });
      }
    };
    return {
      form: {
        userId: "",
        password: "",
        code: "",
      },
      rules: {
        //邮箱验证码
        code: [{ validator: checkEmailPwd, trigger: "blur" }],
      },
      login_tatus: false,
      identifyCode: "",
      flage: false,
      btnText: "发送验证码",
    };
  },
  methods: {
    refreshCode() {
      this.identifyCode = "";
      this.$http.get(this.$store.state.baseUrl + "/getcode", {}).then((res) => {
        // console.log(res)
        if (res.data.code == 200) {
          this.identifyCode = res.data.msg;
        } else {
          this.$message.warning({
            message: "操作太频繁",
            type: "warning",
          });
        }
      });
    },
    yanzhengma() {
      let data = {
        email: this.ruleForm.email,
      };
      if (!data.email) {
        this.open("请输入邮箱", "error");
        return;
      }
      var count = 60;
      this.flage = true;
      this.btnText = count + "s";
      var btnTimer = setInterval(() => {
        count--;
        this.btnText = count + "s";
        if (count == 0) {
          this.flage = false;
          this.btnText = "发送验证码";
          clearInterval(btnTimer);
        }
      }, 1000);
      this.$http
        .get(this.$store.state.baseUrl + "getcode", data)
        .then((res) => {
          if (res.data.code == 200) {
            this.open("发送成功", "success");
          } else {
            this.open("发送失败", "error");
          }
        });
    },
    login() {
      console.log(this.form);
      var that = this;
      if (!this.form.userId || !this.form.password) {
        that.$message.warning({
          message: "请先输入账号密码！",
          type: "warning",
        });

        return;
      }
      if (!this.login_tatus) {
        that.$message.warning({
          message: "请输入正确的验证码",
          type: "warning",
        });
        return;
      }

      var url = this.$store.state.baseUrl + "/justwenlogin";
      this.$http
        .post(url, this.form)
        .then(function(response) {
          console.log(response.data);
          if (response.data.code == 404) {
            that.$message.warning({
              message: "账号密码错误！",
              type: "warning",
            });
            that.$router.go(0);
            return;
          }
          if (response.data.code == 200) {
            console.log(response.data.result);
            that.$Cookie.set("token", response.data.token, 1);
            that.$Cookie.set(
              "cookieUser",
              JSON.stringify(response.data.result),
              1
            );
          }
        })
        .catch(function(error) {
          console.log(error);
          that.$message.warning({
            message: "服务器异常！",
            type: "warning",
          });
        });
    },
  },
  created() {
    this.refreshCode();
    let cookie = this.$Cookie.get("cookieUser");
    if (cookie) {
      let uu = JSON.parse(cookie);
      console.log(uu);
    }
  },
};
</script>
<style lang="scss">
.AdminLogin {
  width: 100%;
  height: 100%;
  background: url(../assets/images/bg.png) no-repeat center;
  background-size: cover;
  overflow: hidden;
  .loginbox {
    margin: 120px auto 0 auto;
    min-height: 420px;
    max-width: 420px;
    padding: 40px;
    background-color: #eeeeee;
    margin-left: auto;
    margin-right: auto;
    border-radius: 4px;
    /* overflow-x: hidden; */
    box-sizing: border-box;
    .message {
      font-size: 14px;
      margin: 10px 0 0 -58px;
      padding: 18px 10px 18px 60px;
      background: #189f92;
      position: relative;
      color: #fff;
      font-size: 16px;
    }
    #darkbannerwrap {
      background: url(../assets/images/aiwrap.png);
      width: 18px;
      height: 10px;
      margin: 0 0 20px -58px;
      position: relative;
    }
    .el-form {
      width: 100%;
      // background-color: red;
      .el-input {
        // border: 1px solid #dcdee0;
        vertical-align: middle;
        border-radius: 3px;
        height: 50px;
        // padding: 0px 16px;
        font-size: 12px;
        color: #555555;
        outline: none;
        width: 100%;
        box-sizing: border-box;
      }
      .el-select {
        width: 100%;
      }
      .el-button {
        margin-top: 15px;
        width: 100%;
        background-color: #189f92;
      }
      .yanzheng {
        .el-form-item__content {
          display: flex;
          height: 40px;
        }
        .el-input {
          width: calc(100% - 193px);
          margin-right: 20px;
        }
        .verify-box {
          width: 121px;
          height: 40px;
          .s-canvas {
            height: 100%;
          }
        }
      }

      .change-pwd {
        text-align: right;
        color: #8b9db6;
        font-size: 12px;
      }
    }
  }
}
</style>
