<template>
  <div class="TecHome">
    <el-container>
      <el-header height="60px"
        >①号教育英语成绩查询系统
        <div class="usermsg">
          <i class="el-icon-user-solid">
            <span>老师管理</span>
          </i>
          <!-- <el-select size="mini" width="30px" v-model="meui" placeholder="菜单">
            <el-option :value="option.value1">>退出</el-option>
            <el-option :value="option.value2">>修改密码</el-option>
          </el-select> -->
        </div>
      </el-header>
    </el-container>
    <el-container class="mincontainer">
      <el-aside width="200px">
        <el-menu :default-openeds="['1', '1']">
          <el-menu-item
            :class="['item', page == item.id ? 'xuan' : '']"
            :key="item.id"
            v-for="item in componentlist"
            @click="qie(item.id, item.name)"
            >{{ item.title }}</el-menu-item
          >
        </el-menu>
      </el-aside>
      <el-container>
        <el-main>
          <keep-alive :max="10">
            <component :is="componentId"></component>
          </keep-alive>
        </el-main>
      </el-container>
    </el-container>
    <!-- <el-container>
      <el-footer height="">Footer</el-footer>
    </el-container> -->
  </div>
</template>

<script>
// @ is an alias to /src
import ScoresPage from "@/components/ScoresPage";
import Upload from "@/components/Upload";
import StudentPage from "@/components/StudentPage";
import Scoreslist from "@/components/Scoreslist";
export default {
  name: "TecHome",
  components: {
    ScoresPage,
    StudentPage,
    Upload,
    Scoreslist,
  },
  data() {
    return {
      option: {
        value1: 1,
        value2: 2,
      },
      meui: "",
      slidebox_status: false,
      componentId: "",
      componentlist: [
        {
          id: 0,
          name: "ScoresPage",
          title: "成绩管理",
        },
        {
          id: 1,
          name: "StudentPage",
          title: "学生管理",
        },
        {
          id: 2,
          name: "Upload",
          title: "成绩*学生录入",
        },
      ],
      page: 0,
    };
  },
  created() {
    this.qie(0, "ScoresPage");
    this.componentId = this.$route.params.id;
    switch (this.$route.params.id) {
      case "ScoresPage":
        this.page = 0;
        break;
      case "StudentPage":
        this.page = 1;
        break;
      case "Upload":
        this.page = 2;
        break;
      case "Scoreslist":
        this.page = 3;
        break;
    }
  },
  methods: {
    qie(page, name) {
      if (this.$route.params.id == name) {
        return;
      }
      this.page = page;
      this.$router.replace("/thome/" + name);
    },
  },
  watch: {
    $route() {
      this.componentId = this.$route.params.id;
      // this.$router.go(0);
    },
  },
};
</script>
<style lang="scss">
.TecHome {
  width: 100%;
  height: 100%;
  .mincontainer {
    height: 100%;
  }
  .el-header {
    background-color: #5b5e63;
    color: #fff;
    text-align: left;
    line-height: 60px;

    font-size: 20px;
    // border-bottom: 1px solid #ffffff;
    .el-select,
    .el-select .el-input.el-input__inner {
      height: 28px;
      width: 40px;

      line-height: 28px;
    }
    .el-input--mini .el-input__inner {
      background: none;
      border: none;
    }
    .usermsg {
      font-size: 15px;
      position: absolute;
      height: 45px;
      width: 130px;
      line-height: 45px;
      right: 0;
      top: 0;
      text-align: center;
      span {
        margin: 0 10px;
      }
    }
  }

  .el-aside {
    background-color: #5b5e63;
    color: #fff;
    text-align: center;
    line-height: 200px;
  }

  .el-main {
    height: 100%;
    background-color: #e9eef3;
    color: #5b5e63;
    text-align: center;
    // line-height: 160px;
  }
  .el-menu {
    background: #444444;
    .item {
      border-bottom: 1px solid #ccc;
      color: white;
      line-height: 50px;
      cursor: pointer;
      &:hover {
        background-color: #189f92;
      }
    }
    .xuan {
      background-color: #189f92;
    }
  }

  body > .el-container {
    margin-bottom: 40px;
  }

  .el-container:nth-child(5) .el-aside,
  .el-container:nth-child(6) .el-aside {
    line-height: 260px;
  }

  .el-container:nth-child(7) .el-aside {
    line-height: 320px;
  }
}
</style>
