<template>
  <div class="wrap">
    <ul id="marquee">
      <li id="movequee" v-for="(item, index) in lists" :key="index">
        {{ item }}
      </li>
    </ul>
  </div>
</template>
<script type="text/ecmascript-6">
export default {
  name: "Msg",
  props: ["lists"], // 父组件传入数据， 数组形式
  methods: {
    move() {
      // 获取内容区宽度
      let width = document.getElementById("marquee").scrollWidth;
      let marquee = document.getElementById("movequee");
        let mw = document.getElementById("movequee").scrollWidth;
      let speed = width; // 位移距离

      // 设置位移
      setInterval(function() {
        speed = speed - 1;
        // 如果位移超过文字宽度，则回到起点
        if (speed <= mw*-1) {
          speed = width;
        }
        marquee.style.transform = "translateX(" + speed + "px)";
      }, 40);
    }
  },
  mounted: function() {
    this.move();
  }
};
</script>
<style scoped>
/*样式的话可以写*/
.wrap {
  overflow: hidden;

  color: red;
}
#box {
  height: 100%;
}
#marquee {
  width: 100%;
  text-align: left;
}
ul,
li {
  margin: 0;
  padding: 0;
}
ul {
  white-space: nowrap;
  margin: 0 10px;
}
li {
  height: 100%;
  list-style: none;
  margin-right: 10px;
  /* background-color: aqua; */
  display: inline-block;
}
</style>
