<template>
  <div class="AdminHome">
    管理员主页
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "AdminHome",
  components: {},
  data() {
    return {
      slidebox_status: false,
    };
  },
  methods: {},
};
</script>
<style lang="scss">
.AdminHome {
  width: 100%;
  height: 100%;
}
</style>
